import { RedocStandalone } from "redoc";
// const STATIC_SAMPLE_API_URL = "https://git.bocs.io/private-api.yaml";
const STATIC_SAMPLE_API_URL = "external.yaml";
function App() {
	return (
		<RedocStandalone
			specUrl={STATIC_SAMPLE_API_URL}
			options={{
				nativeScrollbars: true,
				theme: { colors: { primary: { main: "#150734" } } }
			}}
		/>
	);
}

export default App;
